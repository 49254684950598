<template>
  <div class="content">
    <el-row class="bgW" v-if="!userInfo.shop_id">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="门店名称">
            <el-input v-model="search.shopName" class="w120" clearable placeholder="请输入门店" />
          </el-form-item>
          <el-form-item label="店长名称">
            <el-input v-model="search.shopOwner" class="w140" clearable placeholder="请输入店长名称" />
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="search.type" clearable class="w120" placeholder="请选择统类型">
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="归属市场">
            <el-select v-model="search.marketId" clearable class="w150" placeholder="请选择统归属市场">
              <el-option
                v-for="item in options.marketId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="大区归属">
            <el-select v-model="search.regionId" clearable class="w130" placeholder="请选择统大区归属">
              <el-option
                v-for="item in options.regionId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="search.status" clearable class="w100">
              <el-option
                v-for="item in options.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <el-button
        type="primary"
        v-if="!userInfo.shop_id"
        :disabled="!idList.length"
        class="mb10"
        size="mini"
        @click="hint('导出选中列表')"
      >导出数据</el-button>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="门店名称" prop="shopName" />
            <ElTableColumn label="门店电话" prop="shopPhone" />
            <ElTableColumn label="类型" width="100">
              <template slot-scope="{ row }">
                <div v-if="row.type ==1">直营店</div>
                <div v-if="row.type ==2">加盟店</div>
                <div v-if="row.type ==3">直管店</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="归属市场" prop="marketName" />
            <ElTableColumn label="大区归属" prop="regionName" />
            <ElTableColumn label="所在省-市">
              <template slot-scope="{ row }">
                <div>{{row.provinceName}}-{{row.cityName}}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="状态" width="100">
              <template slot-scope="{ row }">
                <div v-if="row.status ==1">运营中</div>
                <div v-if="row.status ==2">临时关闭</div>
                <div v-if="row.status ==3">永久关闭</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="店长" prop="shopOwner" />
            <ElTableColumn label="店长电话" prop="shopMobile" />
            <ElTableColumn label="操作" width="180" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapDetail(row)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>

          <pagination
            v-if="!userInfo.shop_id"
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  getShopList,
  readAdminMarketList,
  readAdminRegionList,
  readAdminShopTypeList,
  shopExport
} from "@/api/shop";
class Search {
  shopName = ""; // 门店名称
  shopOwner = ""; // 店长名称
  type = ""; // 类型
  marketId = ""; // 归属市场
  regionId = ""; // 归属大区
  status = ""; // 状态
}
class Options {
  constructor() {
    this.type = [];
    this.marketId = [];
    this.regionId = [{ id: 0, name: "全部" }];
    this.status = [
      { id: 1, name: "运营中" },
      { id: 2, name: "临时关闭" },
      { id: 3, name: "永久关闭" }
    ];
  }
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ShopList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  activated() {},
  methods: {
    // 店铺列表
    getList(option) {
      this.loading = true;
      if (option == "search") this.page = new Page();
      getShopList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
        this.loading = false;
      });
    },
    // 获取选项
    getOptions() {
      readAdminMarketList().then(res => {
        this.options.marketId = res.list;
      });
      readAdminRegionList({ page: 1, limit: 10000 }).then(res => {
        this.options.regionId = res.list;
      });
      readAdminShopTypeList().then(res => {
        this.options.type = res.list;
      });
    },
    // 点击详情
    tapDetail(row) {
      // this.$router.push('./shopDetail')
      this.$router.push(
        "./shopDetail?activeName=first&id=" +
          row.shopId +
          "&name=" +
          row.shopName
      );
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      shopExport({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
</style>
